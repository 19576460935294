import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';

import Layout from '../components/Layout';
import {Container} from '../components/Grid';

import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import FAQ from '../components/Page/FAQ';
import PageHero from '../components/shared/hero';
import WorkWithUs from '../components/Page/home/WorkWithUs';
import BookingProcess from '../components/Page/home/BookingProcess';
import {Button} from '../components/Button';
import BookNowController from '../components/BookNowController';
import ImageContent from '../components/Page/houry-wife/ImageContent';
import {Text} from './hourly-wife';

const StyledBlock = styled.div`
  padding: 35px;
  background-color: #f8f9f8;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;
  margin-top: 27px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const DescriptionText = styled.p`
  font-family: avertaFont;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
  }
`;

export const BookButton = ({buttonText}) => {
  return (
    <BookNowController>
      <StyledButton>{buttonText}</StyledButton>
    </BookNowController>
  );
};
export const DescriptionBlock = ({content, button_text}) => {
  return (
    <Container>
      <StyledBlock>
        <DescriptionText>{content}</DescriptionText>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
          }}
        >
          <BookButton buttonText={button_text} />
        </div>
      </StyledBlock>
    </Container>
  );
};

const work_with_us = {
  title2: 'service_prague_list_heading',
  items: [
    {
      title: 'service_prague_list_item_1_header',
      text: 'service_prague_list_item_1_content',
    },
    {
      title: 'service_prague_list_item_2_header',
      text: 'service_prague_list_item_2_content',
    },
    {
      title: 'service_prague_list_item_3_header',
      text: 'service_prague_list_item_3_content',
    },
    {
      title: 'service_prague_list_item_4_header',
      text: 'service_prague_list_item_4_content',
    },
  ],
};

const work_with_us_two = {
  title2: 'service_prague_list_two_heading',
  items: [
    {
      title: 'service_prague_list_two_item_1_header',
      text: 'service_prague_list_two_item_1_content',
    },
    {
      title: 'service_prague_list_two_item_2_header',
      text: 'service_prague_list_two_item_2_content',
    },
    {
      title: 'service_prague_list_two_item_3_header',
      text: 'service_prague_list_two_item_3_content',
    },
    {
      title: 'service_prague_list_two_item_4_header',
      text: 'service_prague_list_two_item_4_content',
    },
    {
      title: 'service_prague_list_two_item_5_header',
      text: 'service_prague_list_two_item_5_content',
    },
  ],
};

const process = {
  title1: 'service_prague_process_heading',
  description: '',
  items: [
    {
      title: 'service_prague_process_item_1_title',
      text: 'service_prague_process_item_1_text',
    },
    {
      title: 'service_prague_process_item_2_title',
      text: 'service_prague_process_item_2_text',
    },
    {
      title: 'service_prague_process_item_3_title',
      text: 'service_prague_process_item_3_text',
    },
    {
      title: 'service_prague_process_item_4_title',
      text: 'service_prague_process_item_4_text',
    },
  ],
};

const ServicePragueOnePage = ({data}) => {
  const {language, t} = useI18next();
  const {
    background,
    backgroundMobile,
    backgroundTablet,
    faq,
    backgroundService,
  } = data;
  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={t('prices_list_seo_title')}
        description={t('prices_list_seo_title_description')}
        lang={language}
        keywords={t('prices_list_seo_keywords')}
      />
      <PageHero
        imgPosition={'95% 0%'}
        content={{
          heading: t(`service_prague_hero_heading`),
          text: t(`service_prague_hero_text`),
          button: t(`service_prague_hero_button`),
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <WorkWithUs
        content={work_with_us}
        bottomDescription={t(``)}
        customDescription={t(`service_prague_list_text`)}
        headingMarginBottom={20}
      />
      <div style={{marginBottom: 50}} />‚
      <DescriptionBlock
        content={t(`service_prague_list_description_block`)}
        button_text={t(`service_prague_list_description_button`)}
      />
      <WorkWithUs
        content={work_with_us_two}
        bottomDescription={t(``)}
        headingMarginBottom={20}
      />
      <div style={{marginBottom: 50}} />
      <DescriptionBlock
        content={t(`service_prague_list_two_descriptionblock`)}
        button_text={t(`service_prague_list_two_button`)}
      />
      {/* <BookingProcess
        content={process}
        bottomButton="ironing_process_bottom_button"
      /> */}
      <div style={{marginBottom: 100}} />
      <ImageContent
        background={backgroundService}
        order="image"
        heading={t(`service_prague_services_heading`)}
        justifyContent="center"
        imgBoxHeight={500}
      >
        <Text>{t(`service_prague_services_description`)}</Text>
        <BookButton buttonText={t(`service_prague_services_button`)} />
      </ImageContent>
      <div style={{marginBottom: 100}} />
      <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
      <div style={{marginBottom: 100}} />
      <DescriptionBlock
        content={t(`service_prague_bottom_description`)}
        button_text={t(`service_prague_bottom_button`)}
      />
      <div style={{marginBottom: 150}} />
    </Layout>
  );
};

export default ServicePragueOnePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "office_disinfection"
            "seo_office_disinfection"
            "homepage"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    backgroundService: file(relativePath: {eq: "hourly-wife-service.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    background: file(relativePath: {eq: "service-prague.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(relativePath: {eq: "service-prague-mobile.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(relativePath: {eq: "service-prague-tablet.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
